(function( $ ) {

    $(".featured-soup-slider, .featured-post-slider").slick({
        dots: true,
        arrows: true, 
        infinite: true,
        adaptiveHeight: true,
        slidesToShow: 3,
        slidesToScroll: 3,    
        autoplay: true,
        autoplaySpeed: 2000,    
        responsive: [
            {
              breakpoint: 890,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    });

    $(".full-slider .slider").slick({
      dots: true,
      arrows: true, 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true, 
      autoplay: true,
      autoplaySpeed: 2000,
    });

    $(".banner.home .image-rotate").slick({
      dots: true,
      arrows: false, 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '5px',
      autoplay: true,
      autoplaySpeed: 2000,
    });

  // accordion event
   $(".collapse.show").each(function(){
    $(this).prev(".card-header").find("svg").addClass("fa-chevron-down").removeClass("fa-chevron-left");
  });
  
  $(".collapse").on('show.bs.collapse', function(){
    $(this).prev(".card-header").find("svg").removeClass("fa-chevron-down").addClass("fa-chevron-left");
  }).on('hide.bs.collapse', function(){
    $(this).prev(".card-header").find("svg").removeClass("fa-chevron-left").addClass("fa-chevron-down");
  });

  // archive ajax soup
  $( ".category #soups" ).click(function() { 

    var category = $(this).attr("value");

    $.ajax({
			url: daris_soup_options.ajaxurl,
			data: {
				action: 'soups_archve_action',
				data: category
			},
      beforeSend: function() {
        $("#spinner-ajax").show();
        $(".soups .container").hide();
      },      
      complete: function() {
        $("#spinner-ajax").hide();
        $(".soups .container").show();
      },
			success: function( response ) {
        $('.soups .container#archive-soup').html(response);

        return false;
			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); 
            alert("Error: " + errorThrown);
    		}
		});

  });

  // archive ajax bog
  $( ".category #blog" ).click(function() {  

    var category = $(this).attr("value");
    
    $.ajax({
			url: daris_soup_options.ajaxurl,
			data: {
				action: 'blog_action',
				data: category
			},
      beforeSend: function() {
        $("#spinner-ajax").show();
        $(".blog .blog .container").hide(); 
      },      
      complete: function() {
        $("#spinner-ajax").hide();
        $(".blog .blog .container").show();
      },
			success: function( response ) {
        $('.blog .blog .container .grid-wrapper').html(response);
        
        return false;
			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); 
            alert("Error: " + errorThrown);
    		}
		});

  });

  // burger menu
  $( '.burger-menu#menu' ).click( function() {
    $( '#sitenav-mobile' ).animate({width: 'toggle'});
	  $( 'body' ).toggleClass( 'sitenav-mobile-active' );

    if ( $( 'body' ).hasClass( 'sitenav-mobile-active' ) ) {
      $( this ).find( '.text' ).text( 'Close' );
    } else {
      $( this ).find( '.text' ).text( 'Menu' );
    }
  } );

  

}( jQuery ));
